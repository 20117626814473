@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	font-family: 'Open Sans', sans-serif;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}

html, body {
	overflow-wrap: break-word;
	scroll-behavior: smooth;
}

input[type="search"]::-webkit-search-cancel-button {
	display: none;
}

.btn-original {
	background: rgba(52, 32, 141, 1) !important;
	border-color: rgba(52, 32, 141, 1);
	color: white !important;
}

body {
  unicode-bidi: embed;
  overflow-x: hidden;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.8rem;
  color: #020307;
  background-color: #f8f7fc !important;
}

a:hover,
a:focus {
	text-decoration: none;
}

a:focus,
button:focus {
	outline: 0;
}

body,
button,
input,
select,
textarea {
    font-family: 'Open Sans', sans-serif;
}

/* Placeholder text color -- selectors need to be separate to work. */
::-webkit-input-placeholder {
	color: rgba(149, 149, 149, 1);
	font-family: 'Open Sans', sans-serif;
}

:-moz-placeholder {
	color: rgba(149, 149, 149, 1);
	font-family: 'Open Sans', sans-serif;
}

::-moz-placeholder {
	color: rgba(149, 149, 149, 1);
	font-family: 'Open Sans', sans-serif;
	opacity: 1; /* Since FF19 lowers the opacity of the placeholder by default */
}

:-ms-input-placeholder {
	color: rgba(149, 149, 149, 1);
	font-family: 'Open Sans', sans-serif;
}

b,
strong {
    font-weight: 600;
}

img,
object,
figure,
iframe,
embed {
	max-width: 100%;
	height: auto;
	border: 0;
}

button,
input {
	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
	line-height: normal;
}

input,
select,
button,
textarea {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
}

select,
input[type="text"],
input[type="url"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="search"] {
	display: block;
	width: 100%;
	padding: .375rem .75rem;
	height: auto;
	color: #020307;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #e8e8e8;
	border-radius: .25rem;
	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

select:focus,
input[type="text"]:focus,
input[type="url"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="search"]:focus {
    color: #020307;
    background-color: #fff;
    border-color: #32208c;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(18, 169, 188,.25);
}

textarea {
	display: block;
	width: 100%;
	min-height: 80px;
	padding: .375rem .75rem;
	color: #020307;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #e8e8e8;
	border-radius: .25rem;
	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	resize: vertical;
}

textarea:focus {
    color: #020307;
    background-color: #fff;
    border-color: #32208c;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(18, 169, 188,.25);
}

.button:not(.btn),
button[type="submit"]:not(.btn),
input[type="submit"]:not(.btn),
input[type="button"]:not(.btn) {
	border: 0;
	margin: 0;
	outline: none;
	cursor: pointer;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

input[readonly="readonly"] {
    background-color: #f5f5f5;
}

.button:not(.btn),
button[type="submit"]:not(.btn),
input[type="submit"]:not(.btn) {
	position: relative;
	display: inline-block;
	padding: .175rem .75rem;
	font-weight: 400;
	line-height: 24px;
	height: auto;
	overflow: hidden;
	cursor: pointer;
	white-space: nowrap;
	color: #fff;
	background-color: #00bff3;
	border: 1px solid #00bff3;
	border-radius: .25rem;
}

.button:not(.btn):hover,
button[type="submit"]:not(.btn):hover,
input[type="submit"]:not(.btn):hover {
	color: #fff;
	background-color: #32208c;
	border-color: #32208c;
}

.button:not(.btn):focus,
button[type="submit"]:not(.btn):focus,
input[type="submit"]:not(.btn):focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba( 50, 32, 140,.5 );
}

label {
	color: #000;
	font-size: 13px;
	font-weight: 600;
}

/**
 * GLOBAL
 */
.transition {
	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}

.bold-300 {
	font-weight: 300 !important;
}

.bold-400 {
	font-weight: 400 !important;
}

.bold-500 {
	font-weight: 500 !important;
}

.bold-600 {
	font-weight: 600 !important;
}

.bold-900 {
	font-weight: 900 !important;
}

.font-10 {
	font-size: 10px !important;
}

.font-11 {
	font-size: 11px !important;
}

.font-12 {
	font-size: 12px !important;
}

.font-13 {
	font-size: 13px !important;
}

.font-14 {
	font-size: 14px !important;
}

.font-15 {
	font-size: 15px !important;
}

.font-16 {
	font-size: 16px !important;
}

.font-17 {
	font-size: 17px !important;
}

.font-18 {
	font-size: 18px !important;
}

.font-19 {
	font-size: 19px !important;
}

.font-20 {
	font-size: 20px !important;
}

.font-21 {
	font-size: 21px !important;
}

.font-22 {
	font-size: 22px !important;
}

.font-23 {
	font-size: 23px !important;
}

.font-24 {
	font-size: 24px !important;
}

.font-28 {
	font-size: 28px !important;
}

.font-30 {
	font-size: 30px !important;
}

.font-36 {
	font-size: 36px !important;
}

.font-44 {
	font-size: 44px !important;
}

.ellipsis {
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.ion {
	font-family: 'Open Sans', sans-serif;
}

.ion:before {
	font-family: 'Ionicons';
}

.text-gray {
	color: #aaa;
}

.bg-purple {
	background-color: #252386;
}

.border-purple {
	border-color: #252386 !important;
}

/**
 * PACE
 */
.pace {
	-webkit-pointer-events: none;
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

.pace-inactive {
	display: none;
}

.pace .pace-progress {
	background: #30b7e4;
	position: fixed;
	z-index: 2000;
	top: 0;
	right: 100%;
	width: 100%;
	height: 2px;
}

.pace .pace-progress-inner {
	display: block;
	position: absolute;
	right: 0px;
	width: 100px;
	height: 100%;
	box-shadow: 0 0 10px #30b7e4, 0 0 5px #30b7e4;
	opacity: 1.0;
	-webkit-transform: rotate(3deg) translate(0px, -4px);
	-moz-transform: rotate(3deg) translate(0px, -4px);
	-ms-transform: rotate(3deg) translate(0px, -4px);
	-o-transform: rotate(3deg) translate(0px, -4px);
	transform: rotate(3deg) translate(0px, -4px);
}

.pace .pace-activity {
	display: block;
	position: fixed;
	z-index: 2000;
	top: 15px;
	right: 15px;
	width: 14px;
	height: 14px;
	border: solid 2px transparent;
	border-top-color: #30b7e4;
	border-left-color: #30b7e4;
	border-radius: 10px;
	-webkit-animation: pace-spinner 400ms linear infinite;
	-moz-animation: pace-spinner 400ms linear infinite;
	-ms-animation: pace-spinner 400ms linear infinite;
	-o-animation: pace-spinner 400ms linear infinite;
	animation: pace-spinner 400ms linear infinite;
}

@-webkit-keyframes pace-spinner {
 0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
 100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@-moz-keyframes pace-spinner {
 0% { -moz-transform: rotate(0deg); transform: rotate(0deg); }
 100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
}
@-o-keyframes pace-spinner {
 0% { -o-transform: rotate(0deg); transform: rotate(0deg); }
 100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
}
@-ms-keyframes pace-spinner {
 0% { -ms-transform: rotate(0deg); transform: rotate(0deg); }
 100% { -ms-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes pace-spinner {
 0% { transform: rotate(0deg); transform: rotate(0deg); }
 100% { transform: rotate(360deg); transform: rotate(360deg); }
}

/**
 * BOOTSTRAP
 */
.tooltip {
  font-family: 'Open Sans', sans-serif;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #fdd037;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #fdd037;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #fdd037;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #fdd037;
}

.tooltip-inner {
	max-width: 100%;
	background-color: #fdd037;
	font-size: 12px;
	white-space: nowrap;
}

.form-control {
	color: #202020 !important;
	border: 1px solid #fff !important;
	border-radius: .4rem !important;
}

.form-control:focus {
	color: #202020 !important;
	border-color: #32208c !important;
	box-shadow: none !important;
}

.form-control::-webkit-input-placeholder {
	color: rgba(149, 149, 149, 1);
	font-family: 'Open Sans', sans-serif;
}

.form-control::-moz-placeholder {
	color: rgba(149, 149, 149, 1);
	font-family: 'Open Sans', sans-serif;
}

.form-control:-ms-input-placeholder {
	color: rgba(149, 149, 149, 1);
	font-family: 'Open Sans', sans-serif;
}

.form-control::-ms-input-placeholder {
	color: rgba(149, 149, 149, 1);
	font-family: 'Open Sans', sans-serif;
}

.form-control::placeholder {
	color: rgba(149, 149, 149, 1);
	font-family: 'Open Sans', sans-serif;
}

.text-info {
	color: #30b7e4 !important;
}

a.text-info:hover,
a.text-info:focus {
	color: #249ac1 !important;
}

.modal .close {
    color: #fff;
		padding: 14px;
}

.modal .slimscroll {
	max-height: 400px;
	height: 100%;
}

.modal > .modal-dialog {
  animation-name: zoomIn;
  animation-duration: 0.3s;
}

@keyframes zoomIn {
  0%   {transform: scale(0.5)}
  100% {transform: scale(1)}
}

/**
 * WRAPPER
 */
#wrapper {
	padding-left: 300px;
}

/**
 * SIDEBAR
 */
#sidebar {
	left: 0;
	top: 0;
	bottom: 0;
	width: 300px;
	z-index: 999;
}

/**
 * WIDGETS
 */
.app-widget {
	border-radius: 20px;
}

.app-widget .widget-title {
	padding: 18px 12px;
	margin-top: -8px;
	margin-right: -8px;
	margin-left: -8px;
	color: #fff;
	background-color: #5a56c4;
	line-height: 20px;
	border-radius: 15px;
}

.app-widget .widget-title.yellow {
	background-color: #ffb822;
}

.app-widget .widget-title.blue {
	background-color: #33b5e6;
}

.app-widget .widget-title.ion {
	position: relative;
	padding-left: 55px;
}

.app-widget .widget-title.ion:before {
	position: absolute;
	left: 5px;
	top: 5px;
	width: 45px;
	height: 45px;
	background: rgba(255,255,255,0.3);
	font-size: 19px;
	line-height: 40px;
	text-align: center;
	border-radius: 15px;
}

/**
 * BOOTSTRAP CAROUSEL
 */
.carousel-control-prev-icon,
.carousel-control-next-icon {
	background: none;
}

.carousel-control-next-icon:before,
.carousel-control-prev-icon:before {
	content:"\f3cf";
	color: #717171;
	font-family: "Ionicons";
	speak: none;
	font-style: normal;
	font-size: 21px;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.carousel-control-next-icon:before {
	content: "\f3d1";
}
.swiper-button-next, .swiper-button-prev {
	color:#727272 !important;
	background: rgba(255,255,255,.5);
	padding: 1rem;
}
.swiper-button-next{
	right:0 !important;
}
.swiper-button-prev {
	left:0 !important;
}

	/**
     * CUSTOM TABS
     */
.submit-post-tabs {
 border-radius: 20px 20px 0 0;
}

.submit-post-tabs .nav-tabs {
 border-bottom: 0;
}

.submit-post-tabs .nav-tabs .nav-item {
	margin-bottom: 0;
}

.submit-post-tabs .nav-tabs .nav-item:not(:last-child) {
	border-right: 1px solid #c9c9c9;
}

.submit-post-tabs .nav-tabs .nav-link {
	position: relative;
	color: #5ca8ca;
	background-color: transparent;
	padding: 10px;
	font-weight: 600;
	border: 0;
	border-bottom: 2px solid #5ea7c7;
	cursor: pointer;
}

.submit-post-tabs .nav-tabs .nav-link:after {
	content: "";
	position: absolute;
	width: 0;
	height: 0;
	right: 50%;
	bottom: 0;
	border-bottom: 1px solid #fff;
	border-right: 7px solid #fff;
	border-left: 7px solid #fff;
	transition: all 0.2s ease-in-out;
}

.submit-post-tabs .nav-tabs .nav-link.active {
	color: #1f1055;
	background-color: transparent;
	border-color: #2c1e59;
}

.submit-post-tabs .nav-tabs .nav-link.active:after {
	border-bottom: 10px solid #ff0103;
}

.submit-post-tabs .article-reaction-title {
  min-height: 38px;
  padding-left: 35px;
	pointer-events: none;
}

.submit-post-tabs .article-reaction-title.lgp {
  padding-left: 40px;
}

.submit-post-tabs .article-reaction-title .icon {
  left: 5px;
  top: 7px;
  width: 20px;
  height: 20px;
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
  background-color: #ccc;
}

.submit-post-tabs .nav-link.active .article-reaction-title .icon {
  background-color: #965ad0;
}

.submit-post-tabs .article-reaction-title .thumb-icon {
  -webkit-mask-box-image: url(../img/svg/like.svg);
  mask-image: url(../img/svg/like.svg);
}

.submit-post-tabs .article-reaction-title .coin-icon {
  -webkit-mask-box-image: url(../img/svg/coins.svg);
  mask-image: url(../img/svg/coins.svg);
}

.submit-post-tabs .article-reaction-title .handshake-icon {
  width: 25px;
  -webkit-mask-box-image: url(../img/svg/handshake.svg);
  mask-image: url(../img/svg/handshake.svg);
}

/**
 * DROPDOWNS
 */
.multiselect-dropdown .dropdown-btn {
	border: 0!important;
	border-radius: 0!important;
	border-bottom: 1px solid #000000!important;
}

.multiselect-dropdown .dropdown-btn:focus {
	outline: 0!important;
	box-shadow: none!important;
	border-color: #9C27B0!important;
}

.multiselect-dropdown .dropdown-btn .selected-item {
	border: 1px solid #9C27B0!important;
	background: #9C27B0!important;
	margin: 0 2px 4px!important;
}

/**
 * FOLLOWERS
 */
.app-followers-list .media img {
	width: 64px;
	height: 64px;
	object-fit: cover;
}

.app-followers-list .media:not(:last-child) {
	border-bottom: 1px solid #eee;
}

.app-followers-list .media .media-body {
    max-width: calc(100% - 72px);
}

/**
 * SHARE
 */
.article-share-wrapper .nav-link {
	width: 50px;
	height: 50px;
	padding: 0;
	color: #fff;
	font-size: 21px;
	line-height: 50px;
	text-align: center;
}

.article-share-wrapper .facebook {
	background-color: #3b5998;
}

.article-share-wrapper .twitter {
	background-color: #55acee;
}

.article-share-wrapper .whatsapp {
	background-color: #43d854;
}

.article-share-wrapper .linkedin {
	background-color: #0077b5;
}

.article-share-wrapper .copy-link .btn {
	right: 0;
	top: 0;
	width: 40px;
	height: 40px;
	text-align: center;
	z-index: 5;
}

.article-share-wrapper .copy-link .btn:focus {
	box-shadow: none;
}
.Box-oop{
	margin: 20px 0 0 0;
	display: flex;
	flex-direction: column;
	background-color: white;
	padding: 20px 40px 20px 40px ;
	min-height: 110px;
	
}

.Box-oop p{
		font-size: 10px;
		padding: 5px;
}

/**
 * STRIPE PAYMENT
 */

/**
 * RESPONSIVE
 */

 @media( min-width: 764px ) {
	.Box-oop{
		margin: 20px 0 0 0;
		display: flex;
		flex-direction: row;
		background-color: white;
		padding: 20px 40px 20px 40px ;
		min-height: 110px;
		
	}
}

@media( max-width: 1399px ) {
	#wrapper {
		padding-left: 250px;
	}

	#sidebar {
		width: 250px;
	}
}


@media( max-width: 1199px ) {
	#wrapper {
		padding-left: 0;
	}

	#sidebar {
		left: -250px;
	}

	#sidebar.open {
		left: 0;
	}
}


/**/

.right-0 {right:0 !important;}

.rounded-bottom-0 {
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.ril__image, .ril__imagePrev, .ril__imageNext {
	width: 25% !important;
	height: auto;
}

@media screen and (max-width: 585px){
	.ril__image, .ril__imagePrev, .ril__imageNext {
		width: 45% !important;
		height: auto;
	}
}
